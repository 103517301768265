var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"8","md":"8","xl":"6","offset-md":"2","offset-lg":"2","offset-xl":"3"}},[_c('h3',{staticClass:"text-h2 mt-4"},[_vm._v("Dstv Price Update")]),_c('p',{staticClass:"text--secondary subtitle-1"},[_vm._v("November 2022")])])],1),_c('v-row',[_c('v-col',{staticClass:"mt-n4",attrs:{"cols":"12","lg":"8","md":"8","xl":"6","offset-md":"2","offset-lg":"2","offset-xl":"3"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" The price of your DStv subscriptions on "),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/"}},[_vm._v("nyangapay.com")]),_vm._v(" just got cheaper. ")],1),_c('p',[_vm._v(" Your DStv subscriptions are settled in the Nigerian Naira (NGN). Due to the falling exchange rate between the naira and the CFA franc (XAF) we have also reduced our prices to pass on these gains directly to you. ")]),_c('h4',{staticClass:"text-h4 mb-2 mt-8"},[_vm._v("New Prices")]),_c('p',[_vm._v(" The table below lists the old and new prices for DStv subscriptions when you don't have the "),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                        name: _vm.$constants.ROUTE_NAMES.DSTV_PRICE_LOCK,
                    }}},[_vm._v("price lock 🔐 feature")]),_vm._v(". ")],1),_c('v-simple-table',[_c('thead',[_c('tr',{staticClass:"text-uppercase"},[_c('th',{staticClass:"text-left"},[_vm._v("DStv Package")]),_c('th',{staticClass:"text-left"},[_vm._v("Old Price")]),_c('th',{staticClass:"text-left"},[_vm._v("New Price")])])]),_c('tbody',[_c('tr',[_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .DSTV_CHANNELS_CONFAM,
                                }}},[_vm._v(" DStv Confam ")])],1),_c('td',{},[_vm._v(" "+_vm._s(_vm._f("currency")("9500"))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")("8000"))+" ")])]),_c('tr',[_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .DSTV_CHANNELS_COMPACT,
                                }}},[_vm._v(" DStv Compact ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")("13500"))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")("11500"))+" ")])]),_c('tr',[_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .DSTV_CHANNELS_COMPACT_PLUS,
                                }}},[_vm._v(" Dstv Compact Plus ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")("19000"))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")("16500"))+" ")])]),_c('tr',[_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .DSTV_CHANNELS_PREMIUM,
                                }}},[_vm._v(" DStv Premium ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")("26500"))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")("24000"))+" ")])]),_c('tr',[_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .DSTV_CHANNELS_COMPACT_PLUS,
                                }}},[_vm._v(" DStv "),(_vm.$vuetify.breakpoint.mdAndDown)?_c('br'):_vm._e(),_vm._v(" Compact Plus "),(_vm.$vuetify.breakpoint.mdAndDown)?_c('br'):_vm._e(),_vm._v(" + Xtra View ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")("22000"))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")("19500"))+" ")])]),_c('tr',[_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .DSTV_CHANNELS_PREMIUM,
                                }}},[_vm._v(" DStv "),(_vm.$vuetify.breakpoint.mdAndDown)?_c('br'):_vm._e(),_vm._v(" Premium "),(_vm.$vuetify.breakpoint.mdAndDown)?_c('br'):_vm._e(),_vm._v(" + Xtra View ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")("30000"))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")("27000"))+" ")])])])]),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","exact":"","to":{ name: _vm.$constants.ROUTE_NAMES.DSTV_INDEX },"block":_vm.$vuetify.breakpoint.mdAndDown}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.tvIcon))]),_vm._v(" Pay DStv Subscription ")],1),_c('h4',{staticClass:"text-h4 mb-2 mt-8"},[_vm._v("New Price Lock 🔐 Prices")]),_c('p',[_vm._v(" The table below lists the old and new prices for DStv subscriptions when you have the "),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                        name: _vm.$constants.ROUTE_NAMES.DSTV_PRICE_LOCK,
                    }}},[_vm._v("price lock 🔐 feature")]),_vm._v(". ")],1),_c('v-simple-table',[_c('thead',[_c('tr',{staticClass:"text-uppercase"},[_c('th',{staticClass:"text-left"},[_vm._v("DStv Package")]),_c('th',{staticClass:"text-left"},[_vm._v("Old Price")]),_c('th',{staticClass:"text-left"},[_vm._v("New Price")])])]),_c('tbody',[_c('tr',[_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .DSTV_CHANNELS_CONFAM,
                                }}},[_vm._v(" DStv Confam ")])],1),_c('td',{},[_vm._v(" "+_vm._s(_vm._f("currency")("8500"))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")("7000"))+" ")])]),_c('tr',[_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .DSTV_CHANNELS_COMPACT,
                                }}},[_vm._v(" DStv Compact ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")("12000"))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")("10000"))+" ")])]),_c('tr',[_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .DSTV_CHANNELS_COMPACT_PLUS,
                                }}},[_vm._v(" Dstv Compact Plus ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")("17500"))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")("14500"))+" ")])]),_c('tr',[_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .DSTV_CHANNELS_PREMIUM,
                                }}},[_vm._v(" DStv Premium ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")("24500"))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")("21000"))+" ")])]),_c('tr',[_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .DSTV_CHANNELS_COMPACT_PLUS,
                                }}},[_vm._v(" DStv "),(_vm.$vuetify.breakpoint.mdAndDown)?_c('br'):_vm._e(),_vm._v(" Compact Plus "),(_vm.$vuetify.breakpoint.mdAndDown)?_c('br'):_vm._e(),_vm._v(" + Xtra View ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")("20000"))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")("17000"))+" ")])]),_c('tr',[_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .DSTV_CHANNELS_PREMIUM,
                                }}},[_vm._v(" DStv "),(_vm.$vuetify.breakpoint.mdAndDown)?_c('br'):_vm._e(),_vm._v(" Premium "),(_vm.$vuetify.breakpoint.mdAndDown)?_c('br'):_vm._e(),_vm._v(" + Xtra View ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")("27000"))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")("24000"))+" ")])])])]),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","exact":"","to":{ name: _vm.$constants.ROUTE_NAMES.DSTV_INDEX },"block":_vm.$vuetify.breakpoint.mdAndDown}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.tvIcon))]),_vm._v(" Pay DStv Subscription ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"px-16",attrs:{"cols":"12","lg":"6","md":"8","offset-md":"2","offset-lg":"3"}},[_c('v-divider',{staticClass:"primary"})],1)],1),_c('v-row',{staticClass:"mt-8 mb-4"},[_c('back-button',{staticClass:"mx-auto"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }